import { getUnselectedApplicants } from "@/Services/apis/applicants.api";
import { JobListType } from "@/Types/Jobs/JobsType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  unselectedCandidatesList: {},
};
export const geUnselectedApplicantsList = createAsyncThunk(
  "geUnselectedApplicantsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getUnselectedApplicantsData: JobListType = await getUnselectedApplicants(value);
      if (getUnselectedApplicantsData?.code !== 200) return rejectWithValue(getUnselectedApplicantsData);
      return getUnselectedApplicantsData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const unSelectedApplicantsSlice = createSlice({
  name: "unSelectedApplicantsSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUnselectedApplicantsLists: (state, action) => {
      state.unselectedCandidatesList = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(geUnselectedApplicantsList.pending, (_state) => {})
      .addCase(geUnselectedApplicantsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.unselectedCandidatesList = {
            code: payload?.code,
            data: {
              list: [...state.unselectedCandidatesList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.unselectedCandidatesList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(geUnselectedApplicantsList.rejected, (_state) => {});
  },
});

export const {
  //eslint-disable-line
  setUnselectedApplicantsLists,
} = unSelectedApplicantsSlice.actions;

export default unSelectedApplicantsSlice.reducer;
