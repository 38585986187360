import { getPublishedApplicants, republishedOrRemovedSelectionApi } from "@/Services/apis/applicants.api";
import { JobListType } from "@/Types/Jobs/JobsType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  publishedCandidatesList: {},
};
export const gePublishedApplicantsList = createAsyncThunk(
  "gePublishedApplicantsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getPublishedApplicantsData: JobListType = await getPublishedApplicants(value);
      if (getPublishedApplicantsData?.code !== 200) return rejectWithValue(getPublishedApplicantsData);
      return getPublishedApplicantsData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const republishedOrRemovedOfCandidate = createAsyncThunk(
  "republishedOrRemovedOfCandidate",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getDataRepublishedOrRemovedSelection: any = await republishedOrRemovedSelectionApi(value);
      if (getDataRepublishedOrRemovedSelection?.code !== 200)
        return rejectWithValue(getDataRepublishedOrRemovedSelection);
      if (getDataRepublishedOrRemovedSelection?.code === 200) {
        toast.success(getDataRepublishedOrRemovedSelection?.message);
        return getDataRepublishedOrRemovedSelection;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const publishedApplicantsSlice = createSlice({
  name: "publishedApplicantsSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPublishedApplicantsLists: (state, action) => {
      state.publishedCandidatesList = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(gePublishedApplicantsList.pending, (_state) => {})
      .addCase(gePublishedApplicantsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.publishedCandidatesList = {
            code: payload?.code,
            data: {
              list: [...state.publishedCandidatesList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.publishedCandidatesList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(gePublishedApplicantsList.rejected, (_state) => {});
  },
});

export const {
  //eslint-disable-line
  setPublishedApplicantsLists,
} = publishedApplicantsSlice.actions;

export default publishedApplicantsSlice.reducer;
