import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  applicantsCard: [],
  interviewDetails: {},
  applicantJobDetails: {},
};

export const applicantsCardSlice = createSlice({
  name: "applicantsCardSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setApplicantsCard: (state, action) => {
      if (action?.payload?.checked) {
        state.applicantsCard = [
          ...state.applicantsCard,
          {
            applicationId: action.payload.applicationId,
            item: action.payload.item,
          },
        ];
      } else {
        state.applicantsCard = state.applicantsCard.filter(
          (app: any) => app.applicationId !== action?.payload?.applicationId
        );
      }
    },
    setApplicantsCardViaPublish: (state, action) => {
      state.applicantsCard = [...action.payload.applicantsCard];
    },
    setApplicantsJobDetails: (state, action) => {
      state.applicantJobDetails = action.payload.applicantJobDetails;
    },
    setInterviewDetails: (state, action) => {
      state.interviewDetails = action.payload.interviewDetails;
    },
  },
});

export const {
  //eslint-disable-line
  setApplicantsCard,
  setApplicantsJobDetails,
  setInterviewDetails,
  setApplicantsCardViaPublish,
} = applicantsCardSlice.actions;

export default applicantsCardSlice.reducer;
