import {
  gePriceDetailsForPublishApi,
  getPaymentIntentApi,
  getShortlistedApplicants,
} from "@/Services/apis/applicants.api";
import { publishRepublishRemovedApi } from "@/Services/apis/interviews/interviewer.api";
import { JobListType } from "@/Types/Jobs/JobsType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  shortListedCandidatesList: {},
  publishInterviewChargeDetails: {},
  paymentIntent: {},
  paymentOrderIdData: {},
};
export const geShortlistedApplicantsList = createAsyncThunk(
  "geShortlistedApplicantsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getAppliedApplicantsData: JobListType = await getShortlistedApplicants(value);
      if (getAppliedApplicantsData?.code !== 200) return rejectWithValue(getAppliedApplicantsData);
      return getAppliedApplicantsData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const gePriceDetailsForPublish = createAsyncThunk(
  "gePriceDetailsForPublish",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const gePriceDetailsForPublishData: any = await gePriceDetailsForPublishApi(value);
      if (gePriceDetailsForPublishData?.code !== 200) return rejectWithValue(gePriceDetailsForPublishData);
      return gePriceDetailsForPublishData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPaymentIntent = createAsyncThunk("getPaymentIntent", async ({ value }: any, { rejectWithValue }) => {
  try {
    const getPaymentIntentData: any = await getPaymentIntentApi(value);
    if (getPaymentIntentData?.code !== 200) return rejectWithValue(getPaymentIntentData);
    return getPaymentIntentData;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const publishRepublishRemovedOfCandidate = createAsyncThunk(
  "publishRepublishRemovedOfCandidate",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getpublishRepublishRemovedSelection: any = await publishRepublishRemovedApi(value);
      if (getpublishRepublishRemovedSelection?.code !== 200)
        return rejectWithValue(getpublishRepublishRemovedSelection);
      if (getpublishRepublishRemovedSelection?.code === 200) {
        return getpublishRepublishRemovedSelection;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const shortListedApplicantsSlice = createSlice({
  name: "shortListedApplicantsSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setShortlistedApplicantsLists: (state, action) => {
      state.shortListedCandidatesList = action.payload;
    },
    setUnselectedApplicantsLists: (state, action) => {
      state.unselectedCandidatesList = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(geShortlistedApplicantsList.pending, (_state) => {})
      .addCase(geShortlistedApplicantsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.shortListedCandidatesList = {
            code: payload?.code,
            data: {
              list: [...state.shortListedCandidatesList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.shortListedCandidatesList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(geShortlistedApplicantsList.rejected, (_state) => {})
      .addCase(gePriceDetailsForPublish.pending, (_state) => {})
      .addCase(gePriceDetailsForPublish.fulfilled, (state, { payload }) => {
        state.publishInterviewChargeDetails = {
          code: payload?.code,
          data: payload?.data,
        };
      })
      .addCase(gePriceDetailsForPublish.rejected, (_state) => {})
      .addCase(getPaymentIntent.pending, (_state) => {})
      .addCase(getPaymentIntent.fulfilled, (state, { payload }) => {
        state.paymentIntent = {
          code: payload?.code,
          stripeClientSecret: payload?.stripeClientSecret,
        };
      })
      .addCase(getPaymentIntent.rejected, (_state) => {})
      .addCase(publishRepublishRemovedOfCandidate.pending, (_state) => {})
      .addCase(publishRepublishRemovedOfCandidate.fulfilled, (state, { payload }) => {
        state.paymentOrderIdData = {
          code: payload?.code,
          message: payload?.message,
          paymentOrderId: payload?.paymentOrderId,
        };
      })
      .addCase(publishRepublishRemovedOfCandidate.rejected, (_state) => {});
  },
});

export const {
  //eslint-disable-line
  setShortlistedApplicantsLists,
} = shortListedApplicantsSlice.actions;

export default shortListedApplicantsSlice.reducer;
