import { getSelectedApplicants } from "@/Services/apis/applicants.api";
import { JobListType } from "@/Types/Jobs/JobsType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  selectedCandidatesList: {},
};
export const getSelectedApplicantsList = createAsyncThunk(
  "getSelectedApplicantsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getAppliedApplicantsData: JobListType = await getSelectedApplicants(value);
      if (getAppliedApplicantsData?.code !== 200) return rejectWithValue(getAppliedApplicantsData);
      return getAppliedApplicantsData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const selectedApplicantsSlice = createSlice({
  name: "selectedApplicantsSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedApplicantsLists: (state, action) => {
      state.selectedCandidatesList = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSelectedApplicantsList.pending, (_state) => {})
      .addCase(getSelectedApplicantsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.selectedCandidatesList = {
            code: payload?.code,
            data: {
              list: [...state.selectedCandidatesList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.selectedCandidatesList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getSelectedApplicantsList.rejected, (_state) => {});
  },
});

export const {
  //eslint-disable-line
  setSelectedApplicantsLists,
} = selectedApplicantsSlice.actions;

export default selectedApplicantsSlice.reducer;
