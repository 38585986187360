import { RootState } from "@/Redux/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { firstLetterCapitalFunc } from "./CommonFuncs";

const useClickable = () => {
  const { user } = useSelector((state: RootState) => state.userProfile);
  const showActiveAccountPop = () => {
    toast.error(firstLetterCapitalFunc("Please activate your account before proceed."));
  };
  return (callback: any) => {
    //eslint-disable-line

    if (user?.isActive) {
      callback();
    } else {
      showActiveAccountPop();
    }
  };
};
export default useClickable;
