import {
  addUpdateJobApi,
  deleteAppliedJobApi,
  deletePostedJobApi,
  getCountDetailsApplicantsApi,
  getJobDetailsCompanyApi,
  getJobDetailsPostedJobApi,
  getJobListApi,
} from "@/Services/apis/job.api";
import { addNewSkillsApi } from "@/Services/apis/profile.api";
import { JobListType } from "@/Types/Jobs/JobsType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { setLoading } from "../loader/loader.slice";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  openJobList: {},
  closeJobList: {},
  selectedOpenOrCloseJob: {},
  companyJobDetails: {},
  postedJobDetails: {},
  applicantsCountsDetails: {},
};
export const getOpenJobList = createAsyncThunk("getOpenJobList", async ({ value }: any, { rejectWithValue }) => {
  try {
    const getJobListData: JobListType = await getJobListApi(value);
    if (getJobListData?.code !== 200) return rejectWithValue(getJobListData);
    return getJobListData;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const getCloseJobList = createAsyncThunk("getCloseJobList", async ({ value }: any, { rejectWithValue }) => {
  try {
    const getJobListData: JobListType = await getJobListApi(value);
    if (getJobListData?.code !== 200) return rejectWithValue(getJobListData);
    return getJobListData;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const addNewSkillsForJobPost = createAsyncThunk(
  "addNewSkillsForJobPost",
  async ({ valueForAddSkillsApi, value }: any, { rejectWithValue }) => {
    try {
      const addNewSkillsData: any = await addNewSkillsApi(valueForAddSkillsApi);
      if (addNewSkillsData?.code !== 200) return rejectWithValue(addNewSkillsData);
      if (addNewSkillsData?.code === 200) {
        if (value?.bodyData?.job_id) {
          value?.dispatch(updateJob(value));
        } else {
          value?.dispatch(addJob(value));
        }
      }
      return addNewSkillsData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addJob = createAsyncThunk("addJob", async (values: any, { rejectWithValue }) => {
  try {
    const addUpdateJobDetails: any = await addUpdateJobApi(values);
    if (addUpdateJobDetails?.code !== 201) return rejectWithValue(addUpdateJobDetails);
    if (addUpdateJobDetails?.code === 201) {
      values?.dispatch(setLoading(true));
      toast.success(addUpdateJobDetails?.message);
      values.router.push("/jobs/list?value=1");
      values?.dispatch(setLoading(false));
    }
    return addUpdateJobDetails;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
export const updateJob = createAsyncThunk("updateJob", async (values: any, { rejectWithValue }) => {
  try {
    const addUpdateJobDetails: any = await addUpdateJobApi(values);
    if (addUpdateJobDetails?.code !== 200) return rejectWithValue(addUpdateJobDetails);
    if (addUpdateJobDetails?.code === 200) {
      values?.dispatch(setLoading(true));
      toast.success(addUpdateJobDetails?.message);
      values.router.push("/jobs/list?value=1");
      values?.dispatch(setLoading(false));
    }
    return addUpdateJobDetails;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
export const getJobDetailsCompany = createAsyncThunk(
  "getJobDetailsCompany",
  async (values: any, { rejectWithValue }) => {
    try {
      const addUpdateJobDetails: any = await getJobDetailsCompanyApi(values);
      if (addUpdateJobDetails?.code !== 200) return rejectWithValue(addUpdateJobDetails);

      return addUpdateJobDetails;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getJobDetailsPostedJob = createAsyncThunk(
  "getJobDetailsPostedJob",
  async (values: any, { rejectWithValue }) => {
    try {
      const postedJobDetails: any = await getJobDetailsPostedJobApi(values);
      if (postedJobDetails?.code !== 200) return rejectWithValue(postedJobDetails);

      return postedJobDetails;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//This job delete is for delete from New jobs and Closed jobs
export const deleteJob = createAsyncThunk("deleteJob", async ({ value }: any, { rejectWithValue }) => {
  try {
    const deletePostedJobData: any = await deletePostedJobApi(value);

    if (deletePostedJobData?.code !== 200) return rejectWithValue(deletePostedJobData);
    if (deletePostedJobData?.code === 200) {
      toast.success(deletePostedJobData?.message);
      return deletePostedJobData;
    }
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

//This job delete is for delete from Applied Jobs
export const deleteJobAppliedlist = createAsyncThunk(
  "deleteJobAppliedlist",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const deleteJobAppliedlistData: any = await deleteAppliedJobApi(value);

      if (deleteJobAppliedlistData?.code !== 200) return rejectWithValue(deleteJobAppliedlistData);
      if (deleteJobAppliedlistData?.code === 200) {
        toast.success(deleteJobAppliedlistData?.message);
        return deleteJobAppliedlistData;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCountDetailsApplicants = createAsyncThunk(
  "getCountDetailsApplicants",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getCountDetailsApplicantsData: any = await getCountDetailsApplicantsApi(value);
      if (getCountDetailsApplicantsData?.code !== 200) return rejectWithValue(getCountDetailsApplicantsData);
      return getCountDetailsApplicantsData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const jobSlice = createSlice({
  name: "jobSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedOpenOrCloseJob: (state, action) => {
      state.selectedOpenOrCloseJob = action.payload;
    },
    setCompanyJobDetails: (state, action) => {
      state.companyJobDetails = action.payload;
    },
    setJobDetails: (state, action) => {
      state.postedJobDetails = action.payload;
    },
    setCountDetailsApplicants: (state, action) => {
      state.applicantsCountsDetails = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getOpenJobList.pending, (_state) => {})
      .addCase(getOpenJobList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.openJobList = {
            code: payload?.code,
            data: {
              list: [...state.openJobList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.openJobList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getOpenJobList.rejected, (_state) => {})

      .addCase(getCloseJobList.pending, (_state) => {})
      .addCase(getCloseJobList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.closeJobList = {
            code: payload?.code,
            data: {
              list: [...state.closeJobList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.closeJobList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getCloseJobList.rejected, (_state) => {})
      .addCase(getJobDetailsCompany.pending, (_state) => {})
      .addCase(getJobDetailsCompany.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        state.companyJobDetails = payload?.details;
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getJobDetailsCompany.rejected, (_state) => {})
      .addCase(getJobDetailsPostedJob.pending, (_state) => {})
      .addCase(getJobDetailsPostedJob.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        state.postedJobDetails = payload?.data;
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getJobDetailsPostedJob.rejected, (_state) => {})
      .addCase(deleteJob.pending, (_state) => {})
      .addCase(deleteJob.fulfilled, (_state) => {})
      .addCase(deleteJob.rejected, (_state) => {})
      .addCase(deleteJobAppliedlist.pending, (_state) => {})
      .addCase(deleteJobAppliedlist.fulfilled, (_state) => {})
      .addCase(deleteJobAppliedlist.rejected, (_state) => {})
      .addCase(getCountDetailsApplicants.pending, (_state) => {})
      .addCase(getCountDetailsApplicants.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        state.applicantsCountsDetails = payload?.data;
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getCountDetailsApplicants.rejected, (_state) => {});
  },
});

export const { setSelectedOpenOrCloseJob, setCompanyJobDetails, setJobDetails, setCountDetailsApplicants } =
  jobSlice.actions;

export default jobSlice.reducer;
