import { idTokenFromLocalStorage, idTokenFromSessionStorage } from "@/Utils/config";
import axiosMethods from "../https/https";
import { JobListType } from "@/Types/Jobs/JobsType";
import catchAsync from "@/Utils/catchAsync";
//Post Job
export const addUpdateJobApi = async (values: any) => {
  //eslint-disable-line
  const addUpdateJobData = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return addUpdateJobData;
};
//Job list related to the jobs which user own posted

export const getJobListApi = async (values: any) => {
  //eslint-disable-line
  const getJobListData: JobListType = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getJobListData;
};

//Jobs for me
export const getJobListForMeApi = async (values: any) => {
  //eslint-disable-line
  const getJobListForMeData: JobListType = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getJobListForMeData;
};
//Job after filter selection
export const getFilteredJobListForMeApi = async (values: any) => {
  //eslint-disable-line
  const getFilteredJobListForMeData: JobListType = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getFilteredJobListForMeData;
};
//When user save a job
export const savedJobApi = async (values: any) => {
  //eslint-disable-line
  const getJobListForMeData: JobListType = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getJobListForMeData;
};
// When user delete their own job
export const deleteJobApi = async (values: any) => {
  //eslint-disable-line
  const deleteJobForMe: JobListType = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return deleteJobForMe;
};

// When user delete their saved job
export const deleteSavedJobApi = async (values: any) => {
  //eslint-disable-line
  const deleteSavedJob: JobListType = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return deleteSavedJob;
};

// Company details for job when user post job as role of company
export const getJobDetailsCompanyApi = async (values: any) => {
  //eslint-disable-line
  const getJobDetailsCompanyData = await axiosMethods.post(`/company`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getJobDetailsCompanyData;
};
// Company details for job when user post job as role of company
export const getJobDetailsPostedJobApi = async (values: any) => {
  //eslint-disable-line
  const getJobDetailsPostedJobData = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getJobDetailsPostedJobData;
};

// Company details for job when user post job as role of company
export const closeJobApi = async (values: any) => {
  //eslint-disable-line
  const getCloseJobResponse = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getCloseJobResponse;
};

// Job share api
export const shareJobApi = async (values: any) => {
  //eslint-disable-line
  const getShareJobResponse = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getShareJobResponse;
};

//Apply job
export const applyJobApi = async (values: any) => {
  //eslint-disable-line
  const applyJobJobData = await axiosMethods.post(`/job-apply`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return applyJobJobData;
};
//Applied job lists

export const getJobListForMeAppliedApi = async (values: any) => {
  //eslint-disable-line
  const getJobListForMeData: JobListType = await axiosMethods.post(`/job-apply`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getJobListForMeData;
};
// When user delete their job from Closed Roles/New Jobs
export const deletePostedJobApi = async (values: any) => {
  //eslint-disable-line
  const deletePostedJob: JobListType = await axiosMethods.post(`/jobs`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return deletePostedJob;
};

// When user delete their job from Applied Jobs
export const deleteAppliedJobApi = async (values: any) => {
  //eslint-disable-line
  const deleteAppliedJob: JobListType = await axiosMethods.post(`/job-apply`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return deleteAppliedJob;
};

export const getS3SignUrlForApplyJob = catchAsync(async (values) => {
  const data = await axiosMethods.post(`/job-apply`, values.dataFiles, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: "get-application-file-pre-signed",
      ProfileRole: values?.role,
    },
  });

  return data;
});

export const withDrawnApplication = async (values: { bodyData: { applicationId: string } }) => {
  //eslint-disable-line
  const data: { code: number; message: string } = await axiosMethods.post(`/job-apply`, values.bodyData, {
    headers: {
      Resource: "withdraw-application",
      ProfileRole: "Default",
    },
  });

  return data;
};

//Application details of counting in review-applicants page
export const getCountDetailsApplicantsApi = async (values: any) => {
  //eslint-disable-line
  const getCountDetailsApplicantsData: any = await axiosMethods.post(
    //eslint-disable-line
    `/job-apply`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getCountDetailsApplicantsData;
};

export const getInviteApplyDetailsApi = async (values: any) => {
  //eslint-disable-line
  const getInviteDetailsData = await axiosMethods.post(`/job-apply`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getInviteDetailsData;
};

//Applied lists>>>Accept for published interview
export const acceptInterview = async (values: { bodyData: { jobApplicationId: string } }) => {
  //eslint-disable-line
  const data: { code: number; message: string } = await axiosMethods.post(`/interview`, values.bodyData, {
    headers: {
      Resource: "accept-interview",
      ProfileRole: "Default",
    },
  });

  return data;
};
