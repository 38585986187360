import { createSlice } from "@reduxjs/toolkit";
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  loading: false,
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const loaderSlice = createSlice({
  name: "loaderSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = loaderSlice.actions;

export default loaderSlice.reducer;
