import { getAddedApplicantsManually, inviteCandiadtesManuallyApi } from "@/Services/apis/applicants.api";
import { JobListType } from "@/Types/Jobs/JobsType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  addedCandidatesList: {},
};
export const getAddedApplicantsList = createAsyncThunk(
  "getAddedApplicantsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getAddedApplicantsData: JobListType = await getAddedApplicantsManually(value);
      if (getAddedApplicantsData?.code !== 200) return rejectWithValue(getAddedApplicantsData);
      return getAddedApplicantsData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const iniveCandidatesManually = createAsyncThunk(
  "iniveCandidatesManually",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const iniveCandidatesManuallyData: any = await inviteCandiadtesManuallyApi(value);
      if (iniveCandidatesManuallyData?.code !== 200) return rejectWithValue(iniveCandidatesManuallyData);
      toast.success(iniveCandidatesManuallyData?.message);
      return iniveCandidatesManuallyData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addedApplicantsSlice = createSlice({
  name: "addedApplicantsSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAddedApplicantsList.pending, (_state) => {})
      .addCase(getAddedApplicantsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.addedCandidatesList = {
            code: payload?.code,
            data: {
              list: [...state.addedCandidatesList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.addedCandidatesList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getAddedApplicantsList.rejected, (_state) => {})
      .addCase(iniveCandidatesManually.pending, (_state) => {})
      .addCase(iniveCandidatesManually.fulfilled, (_state) => {})
      .addCase(iniveCandidatesManually.rejected, (_state) => {});
  },
});

export const {
  //eslint-disable-line
} = addedApplicantsSlice.actions;

export default addedApplicantsSlice.reducer;
